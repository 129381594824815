import { omit } from "@styled-system/props";
import {
  Box,
  Flex,
  PrimaryButton,
  SystemProps,
  TertiaryButton,
  Text,
  TransparentButton,
} from "flicket-ui";
import { useRouter } from "next/router";
import { SyntheticEvent, useContext, useEffect } from "react";
import { Icon, Row as StyledRow } from "~components";
import { BaseContext } from "~context";
import { EventQuery } from "~graphql/sdk";
import { formatDate } from "~lib";
import {
  AttributeNames,
  InteractionNames,
} from "~telemetry/enums/AttributeNames";
import useCaptureClick from "~telemetry/traces/useCaptureClick";

type RowProps = Partial<EventQuery["event"]> &
  SystemProps & {
    isMembership?: boolean;
    locations: string[];
  };

export const Row = ({
  id,
  title,
  hasPublicRelease,

  isMembership,
  locations,
  ...props
}: RowProps) => {
  const router = useRouter();
  const organization = useContext(BaseContext);

  const captureClick = useCaptureClick({
    forwardToNest: true,
    eventId: !isMembership && id,
    pageName: "event-list-page",
  });

  const startDate = props.startDate;
  const endDate = props.endDate;

  const navigateEvent = async () =>
    isMembership
      ? router.push("/memberships/[membershipId]", `/memberships/${id}`)
      : router.push("/events/[eventId]", `/events/${id}`);

  const navigateReservation = async (e: SyntheticEvent) => {
    e.stopPropagation();

    return isMembership
      ? router.push(
          "/memberships/[membershipId]/reservation",
          `/memberships/${id}/reservation`
        )
      : router.push(
          "/events/[eventId]/reservation",
          `/events/${id}/reservation`
        );
  };

  useEffect(() => {
    void router.prefetch("/events/[eventId]", `/events/${id}`);
    void router.prefetch(
      "/events/[eventId]/reservation",
      `/events/${id}/reservation`
    );
    void router.prefetch(
      "/memberships/[eventId]/reservation",
      `/memberships/${id}/reservation`
    );
    void router.prefetch("/memberships/[eventId]", `/memberships/${id}`);
  }, []);

  return (
    <StyledRow
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={{ _: 0, md: 3 }}
      px={{ _: 0, md: 2 }}
      pt={{ _: "6/4", md: 2 }}
      pb={{ _: "6/4", md: 2 }}
      onClick={navigateEvent}
      {...omit(props)}
      key={id}
    >
      <Flex alignItems="center" flex="1" minW={0}>
        {organization.organization.slug === "artfair" ||
        organization.organization.slug === "blossomvalley" ? null : (
          <Flex
            mr={{ _: 2, md: 4 }}
            flexDir="column"
            minW={{ _: "80px", md: "auto" }}
            w={{ _: "80px", md: "115px" }}
          >
            {endDate &&
            formatDate(startDate, "dd") !== formatDate(endDate, "dd") ? (
              <Text
                color="N800"
                lineHeight="medium"
                fontWeight={{ _: "medium", md: "extraBold" }}
                fontSize={{ _: 2, md: 4 }}
              >
                {formatDate(startDate, "dd")} - {formatDate(endDate, "dd MMM")}
              </Text>
            ) : (
              <>
                <Text
                  color="N800"
                  lineHeight="medium"
                  fontWeight={{ _: "medium", md: "extraBold" }}
                  fontSize={{ _: 2, md: 4 }}
                >
                  {formatDate(startDate, "dd MMM")}
                </Text>
                <Text
                  color={{ _: "N800", md: "N600" } as any}
                  mt={{ _: 0, md: "1/2" }}
                  lineHeight={{ _: "18px" as any, md: "normal" }}
                  fontSize={{ _: 2, md: 3 }}
                  fontWeight={{ _: "medium", md: "regular" }}
                  ellipsis
                >
                  {formatDate(startDate, "E, p")}
                </Text>
              </>
            )}
          </Flex>
        )}
        <Flex
          flex="1"
          flexDir="column"
          maxW={{ _: "calc(100% - 96px)", md: "auto" }}
          minW={0}
          mr={3}
        >
          <Text
            color="N800"
            lineHeight="medium"
            fontWeight={{ _: "heavy", md: "extraBold" }}
            fontSize={{ _: 2, md: 4 }}
            textTransform={{ _: "uppercase", md: "none" }}
            maxW="100%"
          >
            {title}
          </Text>
          <Text
            color="N600"
            mt={{ _: 0, md: "1/2" }}
            lineHeight={{ _: "18px" as any, md: "normal" }}
            fontSize={{ _: 2, md: 3 }}
            fontWeight={{ _: "demiBold", md: "regular" }}
          >
            {locations.join(", ")}
          </Text>
        </Flex>
      </Flex>
      <Box width="24px" d={{ 0: "flex", md: "none" }}>
        <TransparentButton>
          <Icon fontSize={6} icon="chevron-right" color="P300" />
        </TransparentButton>
      </Box>
      <Box height="36px" d={{ 0: "none", md: "flex" }}>
        <TertiaryButton px={3} py="1/2">
          {organization.isSinglePage ? (
            <Text fontSize={2} lineHeight={"16px" as any}>
              Ticket Information
            </Text>
          ) : (
            <Text fontSize={2} lineHeight={"16px" as any}>
              Event Information
            </Text>
          )}
        </TertiaryButton>
        {hasPublicRelease && (
          <PrimaryButton
            px={3}
            py="1/2"
            ml="6/4"
            onClick={captureClick(
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              navigateReservation,
              InteractionNames.BUTTON_PRIMARY,
              {
                [AttributeNames.BUTTON_LABEL]: "Buy Tickets",
              }
            )}
          >
            <Text fontSize={2} lineHeight={"16px" as any}>
              Buy Tickets
            </Text>
          </PrimaryButton>
        )}
      </Box>
    </StyledRow>
  );
};
